.app {
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.headerNav {
  background-image: url('../images/leafimage.jpg');
  background-size: cover;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.headerNav :global .navbar-nav>a {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.headerNav a:hover {
  transform: scale(1.01);
}

.header {
  color: #fff;
  padding: 20px;
  font-size: 24px;
  size: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.main {
  flex-grow: 1;
}

.footer {
  background-color: #133d2a;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}

.headerLine {
  border: none;
  height: 2px;
  background-color: #c8c8c8;
  margin: 0;
}


.header-line {
  border: none;
  height: 1px;
  background-color: #85A7B0;
  margin: 0;
}