.root {
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  /* prevent grid blowout */
}

.relations {
  min-width: 30rem
}

.headingError {
  color: var(--amplify-colors-font-error);
  white-space: nowrap;
  font-size: x-small;
  text-align: end;
}

.standardReference {
  align-self: end;
  margin: 2px;
}

.heading {
  font-weight: bold;
}