.create {
  color: green;
}

.update {
  color: blue;
}

.softDelete {
  color: darkgoldenrod;

}

.delete {
  color: red;
}

.removed {
  color: darkred;
}

.removed * {
  text-decoration: line-through red;
}

.added {
  color: green;
}

.timelineIcon {
  background-color: var(--amplify-colors-background-info);
}

.timelineArrow {
  background-color: var(--amplify-colors-background-info);

}

.timelineContent {
  background-color: var(--amplify-colors-background-info);

}