.badges {
  display: inline-block;
  line-height: 2em;
}

.badges>*:not(:last-child) {
  margin-right: .5em;
}


.editableBadge {
  background-color: inherit;
  color: inherit;
  border: solid 1px var(--enum-context-color);
  white-space: nowrap;
}

.editableBadge:global.selected {
  background-color: var(--enum-context-color);
  color: var(--enum-context-contrast-color);
}

.badge {
  border: solid 1px var(--enum-context-color);
  background-color: var(--enum-context-color);
  color: var(--enum-context-contrast-color);
  white-space: nowrap;
}

.editableBadge:global:hover {
  background-color: var(--enum-context-color) !important;
  color: var(--enum-context-contrast-color) !important;
  backdrop-filter: opacity(80%);
}

.editableBadge:global.selected:hover {
  background-color: var(--enum-context-color) !important;
  backdrop-filter: opacity(5%);
  opacity: .5;
}