.wrapper {
  overflow: auto;
  max-height: 100dvh;
  padding: 1em;
}

.date {
  white-space: nowrap;
  font-size: x-small;
}

.user {
  font-weight: bold;
  white-space: nowrap;
}

.comment {
  box-shadow: var(--bs-box-shadow-sm);
}

.otherAuthoredComment {
  translate: -1em 0;
}

.selfAuthoredComment {
  translate: 1em 0;
  border-width: 3px;
}

.userType :global .group {
  color: red;
}