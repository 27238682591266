.breadcrumbs,
.breadcrumbs * {
  display: inline-block;
  list-style-type: none;
  margin-bottom: 0;
}

.breadcrumbs li:not(:first-child)::before {
  content: '>'

}

.wrapper {
  width: 100%
}

.formList {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier;
}