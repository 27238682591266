.root {
  display: flex;
  flex-direction: column;
  max-width: 52rem;
  border-radius: var(--amplify-radii-small);
  border-color: var(--amplify-colors-border-secondary);
  border-width: var(--amplify-border-widths-small);
  border-style: solid;
  border-radius: var(--amplify-radii-small);
  padding: var(--amplify-space-small);

  /* prevent grid blowout */
}


.progress {
  max-width: 50em;
  height: var(--amplify-components-fieldcontrol-line-height);
}