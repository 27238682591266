/* cheeky rule to hide dangling divider (can't use markup because can work out which override name to use - css lastChild should always work!)  */
.customActions :global(.amplify-divider:last-child) {
  display: none;
}

.subtypeTab {
  --bs-nav-pills-link-active-bg: var(--amplify-components-radiogroup-radio-checked-color) !important;
}

h6.standardReference {
  text-align: end;
}

.amplifyForm {
  max-width: 50em;
}


.wrapper {
  display: block;
}

.container {
  margin: auto;
  width: fit-content;
  display: grid;
  gap: var(--amplify-space-small);
  grid-template-columns: repeat(auto-fit, minmax(min-content, 100%));
  grid-auto-flow: column dense;
}

@media (max-width: 576px) {
  .container {
    grid-template-areas:
      "Header"
      "Primary"
      "Secondary"
      "Tertiary"
      "Sidebar"
      "Footer"
    ;
  }
}

@media (min-width: 576px) {
  .container {
    grid-template-areas:
      "Header     Header"
      "Primary    Sidebar"
      "Secondary  Sidebar"
      "Tertiary   Sidebar"
      "Footer     Footer"
    ;
  }
}

@media (min-width: 992px) {
  .container {
    grid-template-areas:
      "Header   Header    Header"
      "Primary  Secondary Sidebar"
      "Primary  Tertiary  Sidebar"
      "Footer   Footer    Footer"
    ;
  }
}


.toolButtons {
  justify-self: end;
}

.area {
  display: block;

}

/**
* Area classes
*/
.Header {
  grid-area: Header;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.Footer {
  grid-area: Footer;
}

.Primary {
  grid-area: Primary;
  justify-self: center;
}

.Secondary {
  grid-area: Secondary;
}

.Tertiary {
  grid-area: Tertiary;
}

.Sidebar {
  grid-area: Sidebar;
}