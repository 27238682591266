.results {

  display: grid;
  padding: .5em;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));

}

.result :global(.card-header) {
  font-weight: bold;
  color: var(--bs-heading-color);
}

.result dl {

  display: flex;
  flex-flow: row wrap;
  border-width: 1px 1px 0 0;
}

.result dt {
  flex-basis: 40%;
  padding: 2px 4px;
  text-align: right;
}

.result dd {
  flex-basis: 60%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 4px;
}

.match {
  color: var(--amplify-colors-red-80);
  font-weight: bold;
}