.formValidationResult {
  max-width: 50em;
}

.formValidationResult li {
  margin-bottom: .2em;
}

.formValidationResult header {
  font-weight: bold;
}