.header :global .icon {
  width: 2em;
  height: 2em;
}

.header :global .success {
  color: var(--amplify-colors-font-success)
}

.header :global .error {
  color: var(--amplify-colors-font-error)
}