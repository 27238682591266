.root {}

.link:hover {
  filter: brightness(120%);
}

.link {
  white-space: nowrap;
  font-size: x-small;
  font-weight: bold;
  text-decoration: none;
  margin: 2px;
}

.nzcs1, .nzcs2, .nzcs3 {
  --reference-accent-color: #5f903f
}


.ifrs-s2, .ifrs-s2-accompanying-guidance, .ifrs-s2-illustrative-guidance {
  --reference-accent-color: #b30938
}

.reference {
  border: solid 1px gray;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  background-color: rgb(254, 254, 254);
  padding: 2px;
  color: var(--reference-accent-color);
}

.logo {
  border: solid 1px var(--reference-accent-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: var(--reference-accent-color);
  padding: 2px;
  color: white
}




.root svg {
  vertical-align: baseline;
}