.deleteButton {
  background-color: #BB685F;
}

.deleteButton:hover {
  background-color: hsl(6, 40%, 45%);
}

.newButton {
  background-color: #1F3D2A;
}

.newButton:hover {
  background-color: hsl(142, 33%, 8%);
}

.editButton:hover {
  background-color: #7A9765;
}

.editButton {
  background-color: hsl(95, 20%, 39%);
}

.cloneButton {
  background-color: hsl(59, 35%, 51%);
}

.cloneButton:hover {
  background-color: hsl(59, 35%, 71%);
}

.button {
  gap: .25em
}

.buttonWrapper {
  white-space: nowrap;
}

.prohibited {
  color: var(--amplify-components-badge-error-color);
  background-color: var(--amplify-components-badge-background-color);
  border-radius: 50%;
  position: relative;
  left: -.5em;
  top: .5em;
}

.downloadButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.splitButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}