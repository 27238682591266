.wrapper {
  overflow: auto;
  min-height: calc((2) * (1em + 1rem));
  max-height: 50vh;
}

.fullSize {
  max-height: none;
}

.panel {
  border: solid 1px var(--amplify-colors-border-tertiary)
}

.selectCell {
  text-align: center;
}

.itemActions {
  vertical-align: middle;
}

.itemActions div {
  display: flex;
  justify-content: flex-end;
  gap: .25em;
}

.globalActions {
  display: flex;
  justify-content: flex-end;
  gap: .5em;
  padding: 0.5em;
  border-top: solid 1px var(--amplify-colors-border-secondary);
}

.itemActions button,
.globalActions button {
  padding: .2em .3em;
  font-size: 1em;

}

.enumBadge {
  background-color: var(--amplify-colors-brand-secondary-40);
}

.errorMessage {
  color: var(--amplify-colors-font-error);
  text-align: center;
}

.columnMenu {
  text-align: end;
  z-index: 99999;
}

.columnMenu :global .checked::before {
  content: '\2713';
}

.columnMenu :global .checked {
  text-indent: 0
}

.columnMenu * {
  text-align: left;
  text-indent: .9em;
}