.recordCard dl {

  display: flex;
  flex-flow: row wrap;
  border-width: 1px 1px 0 0;
  align-items: center;
}

.recordCard dt {
  flex-basis: 40%;
  padding: 2px 4px;
  text-align: right;
}

.recordCard dd {
  flex-basis: 60%;
  flex-grow: 1;
  margin: 0;
  padding: 2px 4px;
}

.match {
  color: red;
  font-weight: bold;
}


.recordCard .check {
  width: 2em;
  height: 2em;
  color: green;
}

.stepGrid {
  display: grid;
  grid-template-columns: minmax(20em, 1fr) minmax(20em, 1fr);
  gap: 1em;
}

.currentStepIcon {
  align-self: end;
  width: 3em;
  height: 3em;
  color: rgba(175, 4, 4, 0.64);
}