.helpBox {
  flex-shrink: 1;
  padding: 2em;
    background-color: var(--amplify-colors-background-secondary);
  box-sizing: border-box;
  border: solid var(--amplify-border-widths-small) var(--amplify-colors-border-secondary);
  border-radius: var(--amplify-radii-medium);
    box-shadow: var(--amplify-shadows-medium);
}

.helpHeader {
  vertical-align: middle;
}
.helpContent li {
  margin-bottom: .75em;
}
.helpContent dl {
  display: grid;
  grid-template-columns: max-content auto;
  column-gap: 1em;
  margin-top: .5em;
}

.helpContent dt {
  grid-column-start: 1;
}

.helpContent dd {
  grid-column-start: 2;
}